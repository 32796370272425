.single-job-items {
  padding: 36px 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-job-items {
    padding: 20px 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-job-items {
    padding: 20px 25px;
  }
}

.single-job-items .company-img img {
  overflow: hidden;
  float: left;
  margin-right: 32px;
  z-index: 999;
}

@media (max-width: 767px) {
  .single-job-items .job-tittle {
    padding-top: 25px;
  }
}

.single-job-items .job-tittle a h4 {
  color: #28395a;
  font-size: 24px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.single-job-items .job-tittle a h4:hover {
  color: #fa9325;
}

.single-job-items .job-tittle ul li {
  display: inline-block;
  margin-right: 48px;
  font-size: 15px;
  color: #808080;
  line-height: 1.8;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-job-items .job-tittle ul li {
    margin-right: 18px;
  }
}

@media (max-width: 767px) {
  .single-job-items .job-tittle ul {
    display: flex;
    flex-direction: column;
  }

  .single-job-items .job-tittle ul li {
    margin-right: 0px;
    margin-bottom: 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-job-items .job-tittle ul li {
    margin-right: 8px;
  }
}

.single-job-items .job-tittle ul li:last-child {
  margin-right: 0px;
}

.single-job-items .job-tittle ul li i {
  font-size: 14px;
  color: #bbbbbb;
  margin-right: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-job-items .job-tittle2 {
    padding-top: 25px;
  }
}

.single-job-items:hover {
  box-shadow: 0px 22px 57px 0px rgba(34, 41, 72, 0.05);
}

.single-job-items .job-items {
  display: flex;
  flex-wrap: wrap;
}