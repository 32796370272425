.card {
    display: block; 
      margin-bottom: 20px;
      line-height: 1.42857143;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
      transition: box-shadow .25s; 
  }
  .card:hover {
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  }
  .img-card {
    width: 100%;
    height:300px;
    border-top-left-radius:2px;
    border-top-right-radius:2px;
    display:block;
    overflow: hidden; 
  }
  .img-card img{
    width: 100%;
    height: 300px;
    object-fit:cover; 
    transition: all .25s ease;
  } 
  .card-content {
    padding:15px;
    text-align:left;
    height: 150px;
    max-height: 150px;
    overflow: hidden;
  }
  .card-title {
    margin-top:0px;
    font-weight: 700;
    font-size: 1.2em;
  }
  .card-title a {
    color: #000;
    text-decoration: none !important;
  }
  .card-read-more {
    border-top: 1px solid #D4D4D4;
    text-align: center;
    height: 42px;
    max-height: 42px;
  }
  .card-read-more a {
    text-decoration: none !important;
    padding:10px;
    font-weight:600;
    text-transform: uppercase
  }