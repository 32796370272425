.job-description p {
  padding: 0;
  font-size: 16px;
  line-height: normal;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* styles.css */
.job-description {
  background-color: #ffffff; /* Fondo blanco */
  border-radius: 8px; /* Bordes redondeados */
  padding: 20px; /* Espaciado interno */
  max-width: 800px; /* Ancho máximo */
  margin: auto; /* Centrar el div */
}

h2 {
  color: #333; /* Color del título */
  font-size: 24px; /* Tamaño del título */
  margin-bottom: 10px; /* Espacio debajo del título */
}

ol {
  padding-left: 20px; /* Sangría para listas */
  margin-bottom: 20px; /* Espacio debajo de la lista */
}

li {
  margin-bottom: 5px; /* Espacio entre elementos de la lista */
  line-height: 1.6; /* Altura de línea */
}

.required-programs {
  font-weight: bold; /* Negrita para programas requeridos */
}

.section-title {
  font-size: 18px; /* Tamaño de los títulos de sección */
  color: #007bff; /* Color azul para los títulos de sección */
  margin-top: 20px; /* Espacio arriba del título de sección */
}

.job-description p {
  margin-bottom: 10px; /* Espaciado inferior entre párrafos */
}

.job-description ol li {
  margin-bottom: 10px; /* Espaciado inferior entre elementos de lista */
}
