//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import './core/base/functions';
@import './core/base/mixins';
@import './core/components/mixins';
@import './core/vendors/plugins/mixins';

// Custom variables
@import 'components/variables.custom';
@import './core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'orange-100': $orange-100,
);

// 3rd-Party plugins variables
@import './core/vendors/plugins/variables';

// Custom layout variables
@import 'layout/variables';
@import './core/layout/docs/variables';

.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000000000;
}

.loading-indicator:after {
  content: 'Cargando...';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 10000000000001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.card-hover {
  transition: transform 0.2s ease;
}

.card-hover:hover {
  transform: scale(1.01);
}

footer {
  background-color: #010b1d !important;
  color: #fff !important;
  font-size: 20px;
  font-weight: 400;

  padding-bottom: 20px;
}

footer h6 {
  color: #fff !important;
  font-size: 20px !important;
  margin-bottom: 29px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}

footer p {
  color: #868c98 !important;
  padding-right: 52px !important;
  font-size: 16px !important;
}

footer p i {
  color: #868c98 !important;
  font-size: 16px !important;
}

footer a:hover {
  color: #009ef7 !important;
  padding-left: 5px !important;
  transition: 0.4s;
}

.footer-social a i {
  margin-left: 13px !important;
  font-size: 14px !important;
  // color: #009ef7 !important;
  transition: 0.4s !important;
}

.footer-social a i:hover {
  color: #009ef7 !important;
  // transition: .4s !important;
}

@media screen and (max-width: 600px) {
  //table responsive for mobile
  table {
    width: 100%;
  }

  table thead {
    display: none;
  }

  table tr,
  table td {
    border-bottom: 1px solid #ddd;
  }

  table tr {
    margin-bottom: 8px;
    border: #868c98 1px solid;
  }

  table td {
    display: flex;
  }

  table td p {
    white-space: normal !important;
    font-size: 2vmin !important;
  }

  table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
    text-align: left !important;
  }

  table > :not(caption) > * > * {
    padding-left: 0 !important;
  }
}

.modal-fullscreen {
  & .ant-modal,
  .ant-modal-content {
    height: 100vh;
    width: 100vw;
  }
  & .ant-modal-body {
    height: calc(100vh - 110px);
  }
}

.ant-modal-wrap.modal-fullscreen.ant-modal-centered {
  text-align: left !important;
}
