.job-list-title {
  padding-top: 50px;
  padding-bottom: 120px;
}

@media screen and (max-width: 992px) {
  .job-list-title {
    padding-top: 0px;
    padding-bottom: 50px;
  }
}
