//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

@import './helpers.scss';

iframe#webpack-dev-server-client-overlay{display:none!important}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.editor-wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 50vh;
}

.editor-class {
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 50vh;
}

.editor-toolbar-class {
  border: 1px solid #757474;
}

.blog-title {
  font-size: 36px;
  color: #203656;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  line-height: 1.4;
  margin: 20px 0;
}

.blog-img {
  height: 712px !important;
  width: 1186px !important;
}

.search-select {
  display: block;
  width: 100%;
  -moz-padding-start: calc(1rem - 3px);
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  border: 1px solid #e4e6ef;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  min-height: calc(1.5em + 1.65rem + 2px);
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}
