.ant-select-selector {
  border-width: 0 !important;
}

.recents-job-list {
  padding-top: 195px;
  padding-bottom: 130px;
}

.recents-job-list h3 {
  color: #28395a;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 95px;
}

@media screen and (max-width: 991px) {
  .searchHomeForm {
    display: block !important;
  }

  .searchHomeForm > div {
    width: 100% !important;
    margin-bottom: 1rem;
  }
  .searchHomeForm > button {
    width: 100% !important;
  }
}
